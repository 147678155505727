<template>
  <!-- input block -->
  <div class="form__body-inputBlock">
    <ComponentInput
      label="Статус"
      :select2Settings="select2Settings"
      :select2Options="statuses"
      v-model="item.statusId"
      type="select2"
      required
      placeholder="Укажите статус"
    />
  </div>
  <!-- input block -->
  <div class="form__body-inputBlock">
    <!-- 2 -->
    <div>
      <p>Описание шага</p>
      <textarea placeholder="Описание шага" v-model="item.name"></textarea>
      <div class="invalid-msg" v-if="v$.item.name.$error">Введите описание не более 500 символов</div>
    </div>
  </div>
  <!-- input block -->
  <div class="form__body-inputBlock">
    <p>Дата</p>
    <div class="modal-date-container">
      <dateComponent v-model="item.date" />
      <div class="invalid-msg" v-if="v$.item.date.$error">Поле обязательно для заполнения</div>
    </div>
  </div>
  <!-- input block -->
  <div class="form__body-inputBlock">
    <ButtonStock title="Сохранить изменения" class="mr-8" @click="save" />
    <ButtonStock btn-type="secondary" title="Отмена" @click="close" />
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength, required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    name: 'TimelineEditor',
    components: {
      ButtonStock,
      dateComponent,
      ComponentInput,
    },
    props: {
      value: Object,
      updateValue: Number,
      statuses: Object,
    },
    watch: {
      updateValue: function () {
        this.item = this.value;
      },
    },
    emits: ['save', 'close'],
    data() {
      return {
        item: this.value ?? {},
        select2Settings: Constants.select2Settings,
      };
    },
    methods: {
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        this.v$.$reset();
        this.$emit('save', Object.assign({}, this.item));
      },
      close() {
        this.$emit('close');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          name: { maxLengthValue: maxLength(500) },
          date: { required },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .modal-date-container::v-deep {
    .v3dp__popout {
      right: unset;
      position: fixed !important;
      z-index: 11110 !important;
    }
  }

  @media (max-height: 820px) {
    .modal-date-container::v-deep {
      .v3dp__heading {
        height: 16px !important;

        &__center {
          line-height: 16px;
        }
      }
      .v3dp__divider {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
      }
      .v3dp__elements button {
        padding: 2px 0 !important;
      }
      .v3dp__popout {
        padding-bottom: 3px !important;
      }
    }
  }
</style>
