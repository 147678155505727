<template>
  <ButtonComponent @click="addComment" class="mb-20" mod="gradient-bg">Добавить</ButtonComponent>
  <div class="comments-table table-container mb-16 js-table-arrow">
    <table class="stockTable">
      <thead>
        <tr>
          <th>Автор</th>
          <th>Добавлен</th>
          <th>Комментарий</th>
          <th>Файл</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="comments.length">
        <tr v-for="(comment, index) in comments" :key="index">
          <td>
            <Tooltip theme="black" is-moving dense modContent="width-360">
              <template #content>
                <span class="comments-table__author">{{
                  (comment.createdBy?.fullName ?? '') + (getUserProfile?.id === comment.createdById ? ' (Вы)' : '')
                }}</span>
              </template>
              <template #default>
                <div class="comments-table__user-info user-info">
                  <p v-if="comment.createdBy?.fullName" class="user-info__text">{{ comment.createdBy.fullName }}</p>
                  <p v-if="comment.createdBy?.email" class="user-info__text">{{ comment.createdBy.email }}</p>
                  <p v-if="comment.createdBy?.position" class="user-info__text">{{ comment.createdBy.position }}</p>
                  <p v-if="comment.createdBy?.organization?.name" class="user-info__text">
                    {{ comment.createdBy.organization.name }}
                  </p>
                  <p v-if="getIsInternalUser(comment) && comment.createdBy?.mobilePhone" class="user-info__text">
                    {{ getPhoneNumber(comment.createdBy.mobilePhone) }}
                  </p>
                  <p
                    v-else-if="!getIsInternalUser(comment) && comment.createdBy?.user?.phoneNumber"
                    class="user-info__text"
                  >
                    {{ comment.createdBy.user.phoneNumber }}
                  </p>
                </div>
              </template>
            </Tooltip>
          </td>
          <td>
            {{ $momentFormat(comment.created, 'DD.MM.YYYY HH:mm') }}
          </td>
          <td>
            <template v-if="comment.id">
              <div class="comments-table__comment">{{ comment.comment }}</div>
            </template>
            <template v-else>
              <ComponentInput v-model="comment.comment" type="textarea" required />
            </template>
          </td>
          <td>
            <template v-if="!comment.id">
              <FileUpload
                :entity="comment"
                prop-name="file"
                :entity-index="index"
                @uploaded="fileUploaded"
                @deleteFile="fileDeleted(index)"
              />
            </template>
            <template v-else-if="!!comment.fileId">
              <FileUpload :entity="comment" prop-name="file" :readonly="true" />
            </template>
          </td>
          <td>
            <IconComponent v-if="!comment.id" @click="postComment(comment, index)" name="add_icon" />
          </td>
          <td>
            <IconComponent v-if="!comment.id" @click="deleteComment(index)" name="delete" />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6" class="text-center">Здесь пока никто не оставил комментариев.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import FileUpload from '@/common/components/FileUpload';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent';
  import Tooltip from '@/common/ui/Tooltip.vue';
  import Utils from '@/common/utils';

  import duplicateMethods from '@/modules/measure/mixins/duplicateMethods';
  import {
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '@/modules/measure/store/initiativeRegistry/name';
  import { INITIATIVE_STATUSES, MOVING_DIRECTION } from '@/modules/measure/utils/constants';

  import Api from '../../api/reqsinitiativesttr';

  export default {
    name: 'InitiativeComment',
    mixins: [duplicateMethods],
    components: {
      Tooltip,
      IconComponent,
      FileUpload,
      ComponentInput,
      ButtonComponent,
    },
    data() {
      return {
        comments: [],
      };
    },
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
        getUserProfile: 'auth/oidc/getUserProfile',
      }),
      getTimelineStatusId() {
        const isExport = this.getInitiative.nsiTradingOperationId === MOVING_DIRECTION.EXPORT;

        if (this.isProcessApplicantStatus) {
          return isExport ? INITIATIVE_STATUSES.processExport : INITIATIVE_STATUSES.processImport;
        }

        return isExport ? INITIATIVE_STATUSES.processApplicantExport : INITIATIVE_STATUSES.processApplicantImport;
      },
    },
    created() {
      this.comments = this.getInitiative.comments;
    },
    methods: {
      ...mapMutations({
        setComments: INITIATIVE_REGISTRY_MUTATIONS_NAME.setComments,
        setInitiative: INITIATIVE_REGISTRY_MUTATIONS_NAME.setInitiative,
      }),
      addComment() {
        this.comments.push({
          id: 0,
          createdById: this.getUserProfile.id,
          createdBy: {
            fullName: this.getUserProfile.employee.fullName,
            email: this.getUserProfile.email,
            position: this.getUserProfile.employee.position,
            mobilePhone: this.getUserProfile.employee.mobilePhone,
            organization: {
              name: this.getUserProfile.employee.organizationName,
            },
            user: {
              phoneNumber: this.getUserProfile.phoneNumber,
              userTypeId: this.getUserProfile.userTypeId,
            },
          },
          created: new Date(),
          comment: '',
          file: {},
          fileId: null,
        });
      },
      async postComment(comment, index) {
        if (!this.validate()) return;

        try {
          const response = await Api.addComment(this.getInitiative.id, {
            comment: comment.comment,
            FileIds: comment.fileId ? [comment.fileId] : [],
          });
          const newComment = response.data;
          this.comments[index] = newComment;
          this.setComments(this.comments);
          this.addTimeline();
        } catch (error) {
          Constants.alert.fire('Добавление комментария', Constants.commonError, 'error');
          console.error(error?.response?.data);
        }
      },
      async addTimeline() {
        const response = await Api.addTimeline(this.getInitiative.id, {
          id: 0,
          date: moment().format('YYYY-MM-DD'),
          name: '',
          statusId: this.getTimelineStatusId,
        });

        const newInitiative = {
          ...this.getInitiative,
          statusId: this.getTimelineStatusId,
          timeline: [...this.getInitiative.timeline, response.data],
        };
        this.setInitiative(newInitiative);
      },
      deleteComment(index) {
        this.comments.splice(index, 1);
      },
      fileUploaded(e) {
        this.comments[e.entityIndex].file = e.data;
        this.comments[e.entityIndex].fileId = e.data ? e.data.id : null;
      },
      fileDeleted(index) {
        if (this.comments[index] && this.comments[index].file) {
          this.comments[index].file = {};
          this.comments[index].fileId = null;
        }
      },
      getPhoneNumber(phoneNumber) {
        return Utils.getPreparedMobilePhone(phoneNumber);
      },
      getIsInternalUser(comment) {
        return comment.createdBy.user?.userTypeId === Constants.userType.internal;
      },
      validate() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей.', 'error');
          return false;
        }
        return true;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
  };
</script>

<style lang="scss" scoped>
  .comments-table {
    &__author {
      cursor: pointer;
    }

    th {
      text-align: left;
    }

    &__comment {
      white-space: pre;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__text {
      font: $fira-16-20 !important;
    }
  }
</style>
