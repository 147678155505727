<template>
  <div class="container-page">
    <PageTitle title="Заявка/инициатива о применении мер ТТР" />

    <div class="initiative-card">
      <div class="initiative-card__body">
        <div v-if="isLoadedPage">
          <Tabs @select="tabSelected">
            <Tab name="Сведения по заявлению" :selected="true">
              <TabStep
                :components="components"
                :tab-navigation="!isCanInitiativeEditing"
                confirm-button="Обновить заявление"
                @step="(id) => (currentStepId = id)"
                :cancel-route="{ name: 'RequestInitiativeTtrRegistry' }"
              />
            </Tab>
            <Tab name="Комментарии">
              <InitiativeComment></InitiativeComment>
            </Tab>
            <Tab name="Таймлайн"> <InitiativeTimeline></InitiativeTimeline></Tab>
            <Tab name="История изменений">
              <InitiativeHistory :history="history" :loading="isHistoryLoading"></InitiativeHistory>
            </Tab>
          </Tabs>
        </div>
        <div class="initiative-card__events">
          <ButtonComponent
            v-if="isShowCloseButton"
            class="initiative-card__close-button"
            :class="{ 'initiative-card__close-button_large-intention': isLargeIntentionCloseButton }"
            mod="border"
            @click="close"
            >Закрыть</ButtonComponent
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { createVNode, defineComponent } from 'vue';
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import Tabs from '@/common/components/redesigned/Tabs/Tab.vue';
  import Tab from '@/common/components/redesigned/Tabs/TabItem.vue';
  import TabStep from '@/common/components/redesigned/Tabs/TabsSteps/TabStep.vue';

  import InitiativeHistory from '@/modules/measure/components/initiativeComponents/InitiativeHistory.vue';
  import duplicateMethods from '@/modules/measure/mixins/duplicateMethods';
  import {
    INITIATIVE_REGISTRY_ACTIONS_NAME,
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '@/modules/measure/store/initiativeRegistry/name';

  import LogApi from '../../admin/api/eventlog';
  import InitiativeComment from '../components/initiativeComponents/InitiativeComment.vue';
  import InitiativeJustificationMeasure from '../components/initiativeComponents/initiativeForm/InitiativeJustificationMeasure.vue';
  import InitiativeMainInfo from '../components/initiativeComponents/initiativeForm/InitiativeMainInfo.vue';
  import InitiativeRate from '../components/initiativeComponents/initiativeForm/initiativeRate/InitiativeRate.vue';
  import InitiativeTimeline from '../components/initiativeComponents/InitiativeTimeline.vue';

  export default {
    name: 'RequestInitiativeTtrCard',
    mixins: [duplicateMethods],
    components: {
      InitiativeHistory,
      InitiativeTimeline,
      TabStep,
      PageTitle,
      Tabs,
      Tab,
      ButtonComponent,
      InitiativeComment,
    },
    data() {
      return {
        id: this.$route.params.id,
        entityTypeId: 34,
        history: [],
        isHistoryLoading: false,
        components: [
          {
            component: createVNode(defineComponent(InitiativeMainInfo)),
            props: { tabTitle: 'Основная информация' },
          },
          {
            component: createVNode(defineComponent(InitiativeRate)),
            props: { tabTitle: 'Ставки' },
          },
          {
            component: createVNode(defineComponent(InitiativeJustificationMeasure)),
            props: { tabTitle: 'Обоснование предлагаемой меры' },
          },
        ],
        isLoadedPage: false,
        currentStepId: 1,
        currentTabIndex: 0,
      };
    },
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
      }),
      isLargeIntentionCloseButton() {
        return this.currentStepId === 1 && this.currentTabIndex === 0;
      },
      isShowCloseButton() {
        return this.currentTabIndex !== 0 || !this.isCanInitiativeEditing;
      },
    },
    async created() {
      await this.loadCard();
      this.isLoadedPage = true;
    },
    unmounted() {
      this.resetState();
    },
    methods: {
      ...mapActions({
        findInitiative: INITIATIVE_REGISTRY_ACTIONS_NAME.findInitiative,
      }),
      ...mapMutations({
        resetState: INITIATIVE_REGISTRY_MUTATIONS_NAME.resetState,
        setInitiative: INITIATIVE_REGISTRY_MUTATIONS_NAME.setInitiative,
      }),
      async loadCard() {
        if (this.id > 0) {
          await this.findInitiative(this.id);
        }
      },
      async loadHistory() {
        this.isHistoryLoading = true;

        try {
          const response = await LogApi.search({
            pageNumber: 1,
            pageSize: 0,
            entityTypeIds: [this.entityTypeId],
            itemId: this.id,
            includeDescription: true,
          });
          this.history = response.data;
        } catch (err) {
          console.error(err);
        } finally {
          this.isHistoryLoading = false;
        }
      },
      tabSelected(index) {
        this.currentTabIndex = index;

        if (index === 3 && !this.history.items?.length) {
          this.loadHistory();
        }
      },
      close() {
        this.$router.push({ name: 'RequestInitiativeTtrRegistry' });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .initiative-card {
    &__events {
      margin: 24px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media (max-width: $mobile-2) {
        align-items: stretch;
      }
    }

    &__close-button {
      margin: 16px 0;

      @media (max-width: $mobile-2) {
        margin: 0 0 16px 0;
        width: 100%;
      }

      &_large-intention {
        @media (max-width: $mobile-2) {
          margin-top: 112px;
        }
      }
    }
  }

  :deep(.tabs-redesign__content) {
    margin-top: 40px;
  }
</style>
