<template>
  <Timeline :timeline="timeline" :statuses="statuses" @save="saveTimeline" @remove="deleteTimelineStep" />
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';

  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import Timeline from '@/components/timeline/Timeline.vue';

  import {
    INITIATIVE_REGISTRY_GETTERS_NAME,
    INITIATIVE_REGISTRY_MUTATIONS_NAME,
  } from '@/modules/measure/store/initiativeRegistry/name';

  import NsiApi from '../../../nsi/api/nsi';
  import Api from '../../api/reqsinitiativesttr';

  export default {
    name: 'InitiativeTimeline',
    components: {
      Timeline,
    },
    data: function () {
      return {
        timeline: [],
        statuses: [],
        exportMeasureIds: [1, 2],
        importMeasureIds: [3, 4, 5, 6],
      };
    },
    computed: {
      ...mapGetters({
        getInitiative: INITIATIVE_REGISTRY_GETTERS_NAME.getInitiative,
      }),
    },
    created() {
      this.loadStatuses();
    },
    methods: {
      ...mapMutations({
        setTimeline: INITIATIVE_REGISTRY_MUTATIONS_NAME.setTimeline,
      }),
      async saveTimeline(item) {
        item.id ? await this.updateTimeline(item) : await this.createTimeline(item);

        this.setTimeline(this.timeline);
      },
      async updateTimeline(updatedItem) {
        try {
          await Api.updateTimeline(this.getInitiative.id, updatedItem);
          const index = this.timeline.findIndex((x) => x.id === updatedItem.id);
          this.timeline.splice(index, 1, updatedItem);
        } catch (err) {
          Constants.alert.fire('Добавление шага', Constants.commonError, 'error');
          console.error(err?.response?.data);
        }
      },
      async createTimeline(item) {
        try {
          const response = await Api.addTimeline(this.getInitiative.id, item);
          this.timeline.push(response.data);
        } catch (err) {
          Constants.alert.fire('Добавление шага', Constants.commonError, 'error');
          console.error(err?.response?.data);
        }
      },
      async deleteTimelineStep(data) {
        const response = await Constants.alert.fire(Constants.confirmOptions.delete);

        if (response.isConfirmed) {
          try {
            await Api.deleteTimeline(this.getInitiative.id, data.item.id);
            this.timeline.splice(data.index, 1);
          } catch (err) {
            Constants.alert.fire('Удаление шага', Constants.commonError, 'error');
            console.error(err?.response?.data);
          }
        }
      },
      async loadStatuses() {
        try {
          const request = { pageNumber: 1, pageSize: 0 };
          if (this.exportMeasureIds.includes(this.getInitiative.measureTypeId))
            request.tradingOperationId = Constants.tradingOperation.export;
          else if (this.importMeasureIds.includes(this.getInitiative.measureTypeId))
            request.tradingOperationId = Constants.tradingOperation.import;
          const response = await NsiApi.search('nsirequestttrstatus', request);

          this.statuses = Utils.groupList(response.data.items, (x) => ({
            id: x.tradingOperationId,
            text: x.tradingOperation.name,
          }));
        } catch (err) {
          console.error(err.response.data);
        }
      },
    },
    watch: {
      getInitiative: {
        handler(newValue) {
          this.timeline = newValue.timeline;
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .comment-text {
    white-space: pre;
  }
</style>
