<template>
  <div class="table-container mb-16 js-table-arrow">
    <table class="stockTable">
      <thead>
        <tr>
          <th>Пользователь</th>
          <th>Дата и время</th>
          <th>Действие</th>
          <th>Подробности</th>
        </tr>
      </thead>
      <tbody v-if="history.items?.length && !loading">
        <tr v-for="item in history.items" :key="item.id">
          <td>{{ item.userName }}</td>
          <td>{{ item.formattedDate }}</td>
          <td>{{ item.permissionName }}</td>
          <td class="comment-text">{{ item.description }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoading">
        <tr>
          <td class="text-center" colspan="4">Загрузка...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center" colspan="4">Нет данных.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'InitiativeHistory',
    props: {
      history: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
